import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Stack, ComponentWrapper, Button } from 'ascential-horizon'
import { windowExists } from '../utils'
import Layout from '../Layout'

function RestrictedPage() {
  const data = useStaticQuery(graphql`
    query {
      contentfulHeader(instanceName: { eq: "Main Header" }) {
        primaryNavigation {
          item {
            label
            url
          }
          subNavigation {
            navItems {
              label
              url
            }
          }
        }
        utilityNavigation {
          label
          url
        }
        userLink
        ctaLabel
        ctaUrl
      }
      contentfulFooter(instanceName: { eq: "Main Footer" }) {
        columns {
          ... on ContentfulNavigation {
            instanceName
            navItems {
              label
              url
            }
          }
          ... on ContentfulTextBlock {
            heading
            copy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        utilityNavigation {
          navItems {
            label
            url
          }
        }
        socialLabel
        facebookUrl
        twitterUrl
        linkedInUrl
        youTubeUrl
        instagramUrl
      }
    }
  `)

  /**
   * Adds a simple check to see if `windowExists`, ultimately preventing
   * the cursed 404 flash[0] when deployed somewhere like Netlify. Huge thanks to
   * Sam Featherstone (GitHub @samburgers) for the suggestion.[1]
   *
   * [0]: https://github.com/gatsbyjs/gatsby/issues/5329
   * [1]: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
   */

  return (
    windowExists && (
      <Layout
        header={data.contentfulHeader}
        footer={data.contentfulFooter}
        slug="/restricted"
        pageType="/">
        <ComponentWrapper>
          <Stack space="xlarge">
            <div>
              <h2>Your account cannot access this content.</h2>
              <p>
                Feel free check our faqs page for more information and ways to
                contact us.
              </p>
              <Button variant="primary" url="/faqs">
                faqs
              </Button>
            </div>
          </Stack>
        </ComponentWrapper>
      </Layout>
    )
  )
}

export default RestrictedPage
